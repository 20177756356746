<template>
	<a class="to-top" @click="scrollToTop()">
		<div>
			<div class="scroll-icon" />
		</div>
	</a>
</template>

<script setup>
const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.to-top');
	if (window.pageYOffset >= 350) {
		fixedElements.forEach((el) => el.classList.add('show'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('show'));
	}
};

const scrollToTop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
a.to-top,
.to-top {
	position: fixed;
	bottom: 20px;
	left: 20px;
	opacity: 0;
	cursor: pointer;
	z-index: 100;
	width: 57px;
	height: 57px;
	background: url('~/assets/images/scroll-to-top.png') no-repeat center center;
	background-size: 57px;
	border: none;
	outline: none;

	&::after {
		display: none;
	}

	> div {
		position: relative;
		width: 57px;
		height: 57px;

		&::after {
			content: '';
			display: block;
			background: var(--cta-color);
			opacity: 0;
			transition: all 0.3s ease-in-out;
			mix-blend-mode: color;
			position: absolute;
			inset: 2px 7px 7px 2px;
			border-radius: 50%;
		}
	}

	&:hover {
		color: var(--cta-color);

		> div::after {
			opacity: 1;
		}
	}

	&.show {
		opacity: 1;
	}
}

@media (max-width: 600px) {
	.to-top {
		display: none;
	}
}
</style>
