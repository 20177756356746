<template>
	<div v-if="langmenu.length" v-click-outside="close" class="lang-nav" @click="toggle">
		<span>{{ locale }}</span>
		<div class="dropdown-arrow">
			<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
		</div>
		<div v-if="show" class="lang-wrapper">
			<div v-for="item in langmenu" :key="`${item.filename}-${item.language}`">
				<nuxt-link :to="`${item.filename}`" @click="$emit('hide-nav')">
					<span>{{ $t(item.language) }}</span>
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();

await fetchPage();

defineEmits(['hide-nav']);

const show = ref(false);

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggle = () => {
	show.value = !show.value;
};

const close = () => {
	show.value = false;
};
</script>

<style lang="scss" scoped>
.dropdown-arrow {
	background: none;

	svg {
		font-size: 18px;
		margin: 0 0 1px;
	}
}

.lang-nav {
	margin: 0 25px 0 0;
	padding: 5px 0;
	cursor: pointer;
	max-width: 400px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	position: relative;
	border-bottom: 2px solid rgba(0 0 0 / 0%);
	transition: all 0.3s ease-in-out;

	&::after {
		display: block;
		content: '';
		width: 100%;
		height: 1px;
		background: #3c3834;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&.active,
	&:hover {
		color: var(--body-color);

		&::after {
			opacity: 1;
		}
	}

	> span {
		margin: 0 5px 0 0;
	}

	a::after {
		display: none;
	}

	.lang-wrapper {
		position: absolute;
		top: 40px;
		left: 0;
		right: 3px;
		padding: 0;
		min-width: 260px;
		text-align: left;
		background: #fff;
		box-shadow: 0 20px 20px rgba(0 0 0 / 15%);
		z-index: 90;

		&.active,
		a {
			display: flex;
			flex-flow: column nowrap;
			place-content: space-between flex-start;
			color: #000;
			border: none;
		}

		div {
			width: 100%;
			flex-direction: row;
			text-decoration: none;
			margin: 0;

			a {
				text-decoration: none;
				padding: 20px 30px;
				font-size: 14px;

				&:hover {
					background: var(--cta-color);
				}
			}

			span {
				width: 100%;
				padding: 0;
			}
		}
	}
}
</style>
