<template>
	<div>
		<div class="main-nav-list">
			<ul>
				<li class="nav-title">{{ title }}</li>
				<li class="nav-item">
					<span @click="$emit('hide-nav')">
						<a :href="config.public.siteUrlGroup" target="_blank">
							<span>{{ $t('collection') }}</span>
						</a>
					</span>
				</li>
				<li class="nav-item">
					<span @click="$emit('hide-nav')">
						<a :href="config.public.siteUrlGroup + vacancyPage.filename" target="_blank">
							<span>{{ $t('joinOurTeam') }}</span>
						</a>
					</span>
				</li>
				<li class="nav-item">
					<span @click="$emit('hide-nav')">
						<a :href="config.public.siteUrlGroup + magazinePage.filename" target="_blank">
							<span>Vondel Magazine</span>
						</a>
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
const { groupsPages, fetchGroupsPages } = await useGroupsPages();
const { $i18n } = useNuxtApp();

const config = useRuntimeConfig();

await fetchGroupsPages();

$i18n.onBeforeLanguageSwitch(async () => {
	await fetchGroupsPages();
});

const vacancyPage = computed(() => groupsPages.value.find((page) => page.slug === 'vacancies-index'));
const magazinePage = computed(() => groupsPages.value.find((page) => page.slug === 'magazine-index'));

defineProps({
	title: { type: String, default: '' },
});

defineEmits(['hide-nav']);
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.main-nav-list {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0 50px 0 0;
	min-width: 20vw;

	.nav-title {
		text-transform: uppercase;
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;
	}

	ul {
		li {
			list-style: none;
			padding: 15px 0;
			width: 100%;
		}
	}
}

a {
	color: var(--body-color);
	text-decoration: none;
	margin: 0;
	padding: 5px 0;
	font-weight: 500;
	font-size: var(--body-font-size-big);
	transition: all 0.3s ease-in-out;

	span {
		padding: 0 0 7px;
		position: relative;
	}

	&.active,
	&:hover {
		color: var(--body-color);

		span::after {
			opacity: 1;
		}
	}
}

@media (max-width: 1280px) {
	.main-nav-list {
		padding-right: 5px;

		.nav-title {
			margin: 20px 0 10px;
		}
	}
}

@media (max-width: 1200px) {
	.main-nav-list {
		width: 100%;

		ul li {
			padding: 5px 0;
		}
	}

	a {
		font-size: 18px;
	}
}
</style>
