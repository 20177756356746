<template>
	<div :class="show && 'show'" class="fullscreen-nav-container">
		<div class="top-section">
			<div class="menu-close" @click="$emit('hide-nav')">
				<font-awesome-icon :icon="`fal fa-xmark`" size="1x" />
				<span>{{ $t('close') }}</span>
			</div>
			<divider />
			<lang-nav @hide-nav="$emit('hide-nav')" />
		</div>
		<nav class="nav-wrapper">
			<div class="nav-inner-wrapper">
				<main-nav-list
					:items="topmenu.firstColumn[locale]"
					:title="defaults[locale].navigation.navigationTitle1"
					@hide-nav="$emit('hide-nav')"
				/>
				<div class="nav-footer-section">
					<div>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`" class="phone" />
						<a :href="`mailto:${defaults[locale].website.email}`" class="email" />
					</div>
					<divider />
					<social-media :socials="socials" />
				</div>
			</div>
			<div class="vertical-divider nav-divider" />

			<main-nav-list
				:items="topmenu.secondColumn[locale]"
				:title="defaults[locale].navigation.navigationTitle2"
				@hide-nav="$emit('hide-nav')"
			/>

			<group-nav-list :title="defaults[locale].navigation.navigationTitle3" @hide-nav="$emit('hide-nav')" />
		</nav>
		<div class="nav-footer">
			<div class="nav-footer-section">
				<div v-parse-links v-html="defaults[locale].navigation.navigationSubtext" />
			</div>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();
const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();

defineProps({
	show: { type: Boolean, default: false },
});

defineEmits(['hide-nav']);

const { data: topmenu } = await useWebsiteFetch('topmenu');
</script>

<style lang="scss" scoped>
.fullscreen-nav-container {
	z-index: 1003;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);
	background: var(--primary-background-color);
	position: fixed;
	top: 0;
	left: 0;
	padding: 30px;

	.vertical-divider {
		background: #3c3834;
		margin: 0 80px;
		width: 1px;
		height: 100%;
		min-height: 70vh;
	}

	.nav-wrapper {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		height: auto;
		padding: 25px 0 0;
	}

	.menu-close {
		cursor: pointer;
		padding: 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin: 0 30px 0 0;
		text-transform: uppercase;
		font-weight: 900;

		svg {
			font-size: 26px;
			margin: 0 15px 0 0;
		}

		div {
			margin: 0 15px 0 0;
		}
	}
}

.fullscreen-nav-container.show {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.nav-inner-wrapper {
	height: 100%;
	min-height: 70vh;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	align-items: flex-start;

	.nav-footer-section {
		font-size: var(--body-font-size-big);
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		align-self: flex-start;
		margin: 0 0 10px;

		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}

		a {
			display: flex;
			align-items: center;
			place-content: center center;
			width: 30px;
			height: 30px;
			margin: 10px 15px 10px 0;
			text-decoration: none;
			opacity: 0.75;
			background: url('~/assets/images/socials/phone.png') no-repeat center center;
			background-size: 30px;

			&::after {
				display: none;
			}

			&.email {
				background: url('~/assets/images/socials/email.png') no-repeat center center;
				background-size: 30px;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}

.nav-footer {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	.nav-footer-section {
		font-size: var(--body-font-size-big);

		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}

		&:first-child {
			min-width: 61vw;
			border-top: 1px solid #3c3834;
			border-bottom: 1px solid #3c3834;
			font-size: var(--h4-font-size);
		}
	}

	> div {
		display: flex;
		align-items: center;
	}

	:deep(a),
	:deep(a:hover) {
		color: var(--body-color);
		text-decoration: none;
	}

	a {
		display: flex;
		align-items: center;
		place-content: center center;
		width: 30px;
		height: 30px;
		margin: 10px 15px 10px 0;
		text-decoration: none;
		opacity: 0.75;
		background: url('~/assets/images/socials/phone.png') no-repeat center center;
		background-size: 30px;

		&::after {
			display: none;
		}

		&.email {
			background: url('~/assets/images/socials/email.png') no-repeat center center;
			background-size: 30px;
		}

		&:hover {
			opacity: 1;
		}
	}
}

.top-section {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0 20px;
}

@media (max-width: 1200px) {
	.fullscreen-nav-container {
		padding: 40px 30px;
		overflow: hidden scroll;

		.vertical-divider {
			display: none;
		}

		.nav-wrapper {
			flex-flow: column wrap;
			padding: 0 0 50px;
			position: relative;
		}
	}

	.nav-inner-wrapper {
		height: 100%;
		min-height: 0;

		.nav-footer-section {
			position: absolute;
			bottom: -50px;
			left: 0;
			margin: 0;
		}
	}

	.nav-footer {
		position: relative;
		margin: 70px 0 0;
		flex-flow: column wrap;

		.nav-footer-section {
			width: 100%;
		}
	}
}

@media (max-height: 680px) {
	.nav-wrapper .nav-footer {
		display: none;
	}
}
</style>
